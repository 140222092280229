
/**
 * @file
 *
 * Customization script for the 'pam/member' form.
 */

import $ from 'jquery'

$(document).ready(() => {

})
